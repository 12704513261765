/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {
  position: relative;
  background: #151515;
  color: #fff;
  padding: 4.9rem 1.8rem 2rem;
  z-index: 999;

  /*  Mobile  */

  @include breakpoint-down(mobile) {
    padding: 4.5rem 1.8rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.9rem;
    background-size: 100% 100%;
    background-repeat: repeat-x 0 0;
  }

  .grid {
    /*  Mobile  */

    @include breakpoint-down(mobile) {
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
    }

    .grid__col {
      /*  Mobile  */

      @include breakpoint-down(mobile) {
        flex: 0 0 42%;
      }
    }
  }

  .socials {
    position: absolute;
    top: 0;
    right: 0;

    /*  Small Desktop  */

    @include breakpoint-down(small-desktop) {
      right: 1.5rem;
    }

    /*  Tablet  */

    @include breakpoint-down(tablet) {
      position: relative;
      left: 0;
      text-align: center;
      margin: 4rem 0 0;
    }

    /*  Mobile  */

    @include breakpoint-down(mobile) {
      margin-top: 3.2rem;
    }

    ul {
      li {
        a {
          i {
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  .footer__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .footer__inner {
    max-width: 95.4rem;

    /*  Small Desktop  */

    @include breakpoint-down(small-desktop) {
      max-width: calc(100% - 20rem);
    }

    /*  Tablet  */

    @include breakpoint-down(tablet) {
      max-width: 100%;
    }
  }

  .footer__body {
    .shell {
      position: relative;
      padding: 0;
      max-width: calc(126.4rem - 3.6rem);
    }
  }

  .footer__body-inner {
    h6 {
      display: inline-block;
      margin: 0;
      font-size: 1.6rem;
      font-weight: 600;
      vertical-align: top;
      text-transform: uppercase;

      a {
        text-decoration: none;
        transition: opacity 0.4s;

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }

  .footer__nav {
    display: inline-block;
    vertical-align: top;
    padding-left: 1.8rem;
    margin-top: -0.3rem;

    /*  Mobile  */

    @include breakpoint-down(mobile) {
      margin: 0;
      padding: 0;
      display: block;
    }

    a {
      text-decoration: none;
      transition: opacity 0.4s;

      &:hover {
        color: #ffc000;
      }
    }

    li {
      + li {
        margin: 1rem 0 0;
      }
    }
  }

  .footer__bar-inner {
    font-size: 1.4rem;
    line-height: 1;

    /*  Mobile  */

    @include breakpoint-down(mobile) {
      border: none;
      padding-top: 0;
    }

    a:not(.logo) {
      text-decoration: none;
      transition: opacity 0.4s;

      &:hover {
        opacity: 0.7;
      }
    }

    .logo {
      width: 100%;
      margin-bottom: 0.3rem;

      @include breakpoint-up(mobile) {
        max-width: 20.8rem;
      }

      img {
        display: block;
        width: max-content;
      }
    }

    p {
      color: #adadad;
      margin: 0;

      & + p {
        margin-top: 0.4rem;
        font-size: 12px;
      }
    }
  }

  #version-number {
    direction: rtl;
    word-break: keep-all;
  }

  .flow-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
    position: fixed;
    z-index: 99999;
    right: 2.45rem;
    bottom: 2rem;
    width: max-content;

    &[data-is-crisp="true"] {
      right: 9.5rem !important;
    }

    @media screen and (max-width: 480px) {
      right: 1.4rem;
      bottom: 1.37rem;

      &[data-is-crisp="true"] {
        right: 7.5rem !important;
      }
    }
  }
}

.separator {
  text-align: center;
  display: inline-block;
  width: 0.9rem;
}

.footer {
  .grid__col--size1 {
    @include breakpoint-down(mobile) {
      max-width: 100% !important;
    }
  }

  .footer__col {
    display: flex;
    flex-direction: row;
    padding: 0 2.3rem 0 0;

    @include breakpoint-down(tablet) {
      padding: 0 1.8rem 0 0;
    }

    @include breakpoint-down(mobile) {
      flex-direction: column;
      padding: 0;
    }
  }

  .footer__bar {
    @include breakpoint-down(mobile) {
      order: 1;
    }
  }

  .footer__navigations {
    display: flex;

    @include breakpoint-up(mobile) {
      margin: 0 auto;
    }

    @include breakpoint-down(mobile) {
      order: 2;
      flex-wrap: wrap;
      position: relative;
      margin-top: 3rem;
    }
  }

  .footer__nav {
    padding: 0 6.8rem 0 2.4rem;

    @include breakpoint-down(mobile) {
      max-width: 50%;
      flex: 0 0 50%;
      padding: 0 0 0 3rem;
    }

    @include breakpoint-down(mobile-small-medium) {
      padding: 0 !important;
    }

    &::nth-child(even) {
      @include breakpoint-down(mobile-small-medium) {
        padding-left: 1rem !important;
      }
    }
  }

  .footer-nav {
    li {
      list-style: none;

      a {
        text-decoration: none;
        transition: color 0.4s;
        white-space: nowrap;
      }
    }

    @include breakpoint-down(mobile) {
      &:nth-child(n + 3) {
        margin-top: 0.7rem;
      }
    }
  }
}

.footer-site-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 0.8rem !important;
  color: #adadad;
  font-size: 1.4rem;
  line-height: 1;

  &.visible-xs-flex {
    margin-top: 2.5rem !important;
    align-items: center;
  }

  span {
    &:first-child {
      margin-bottom: 0.65rem;
    }

    & + span {
      margin-bottom: 0.5rem;
    }
  }
}

.mobile-footer-menu {
  color: #adadad;
  font-size: 1.2rem;
  line-height: 1;
  text-align: center;
  margin-top: 0.4rem;

  a {
    text-decoration: none;
  }
}

html[lang="ar"] {
  .footer-site-info {
    span {
      &:first-child {
        margin-bottom: 0.8rem;
      }
    }
  }
}

/* ___________________________________________________ LTR ___________________________________________________ */

html[dir="rtl"] {
  .footer {
    &:after {
      left: unset;
      right: 0;
    }

    .socials {
      right: unset;
      left: 0;

      /*  Small Desktop  */

      @include breakpoint-down(small-desktop) {
        left: 1.5rem;
      }

      /*  Tablet  */

      @include breakpoint-down(tablet) {
        left: unset;
        right: 0;
      }
    }

    .footer__col {
      padding: 0 0 0 2.3rem;

      @include breakpoint-down(tablet) {
        padding: 0 0 0 1.8rem;
      }
    }

    .footer__nav {
      padding-left: 0;
      padding-right: 1.8rem;
    }

    .footer__nav {
      padding: 0 2.4rem 0 6.8rem;

      @include breakpoint-down(mobile) {
        padding: 0 4rem 0 0;
      }
    }

    .flow-buttons {
      position: fixed;
      right: revert !important;
      left: 2.45rem;
      bottom: 2rem;

      &[data-is-crisp="true"] {
        left: 9.5rem !important;
      }

      @media screen and (max-width: 480px) {
        left: 1.4rem;
        bottom: 1.37rem;

        &[data-is-crisp="true"] {
          left: 7.8rem !important;
        }
      }
    }
  }
}

.otgs-development-site-front-end {
  display: none;
}
