/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn {
  font-size: 1.8rem;
  font-weight: 700;
  text-decoration: none;
  text-align: center;

  &:hover {
    text-decoration: none;
  }

  &.loadmore {
    display: block;
    width: fit-content;
    margin: 3rem auto;
    user-select: none;
    transition: all 0.3s;
  }
}

.btn--transparent {
  background-color: transparent;
  color: $b-black;
  border: 0.1rem solid $c-gray;
  padding: 1.4rem 2.5rem;

  &:hover {
    background-color: rgba($d-black, 0.2);
  }
}

.button--loading {
  pointer-events: none;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 3rem;
    height: 3rem;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border: 4px solid transparent;
    border-top-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  &:hover {
    cursor: not-allowed !important;

    &:after {
      border-top-color: #151515;
    }
  }

  &.btn--transparent {
    &:after {
      border-top-color: #151515;
    }

    &:hover {
      &:after {
        border-top-color: #ffffff;
      }
    }
  }

  .button__text {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.btn.not_found {
  width: 19rem;
  height: 5.5rem;
  border: 0.2rem solid #ed1b68;
  color: #ed1b68;
  font-weight: 700 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  transition: all 0.5s;

  &:hover {
    color: #fff;
    background: #ed1b68;
  }
}

html[dir="rtl"] {
  .btn {
    font-weight: 500;
  }
}

@media only screen and (min-width: 765px) and (max-width: 850px) {
  .btn.showmore {
    font-size: 1.5rem;
  }
}
