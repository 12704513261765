.editors-pick-slider--container {
  font-family: $ff-base;
  min-height: 368px;
  .section__head {
    text-align: center;
    .label {
      font-size: 40px !important;
      font-weight: 950;
      padding-top: 0 !important;
      padding-bottom: 2rem !important;
      color: hsla(0, 0%, 83%, 0.788);

      @include breakpoint-down(mobile) {
        font-size: 25px !important;
      }
    }
  }

  .slider-desktop {
    @include breakpoint-down(mobile) {
      margin-right: -1.8rem !important;
    }

    .slick-list {
      @include breakpoint-down(mobile) {
        padding-right: 5.2rem;
        margin: 0 -0.5rem;
      }
    }
  }
}
.editors-pick-slider--container-ar {
  font-family: $ff-base-ar !important;

  .slider-desktop {
    @include breakpoint-down(mobile) {
      margin-left: -1.8rem !important;
      margin-right: -0.6rem !important;
    }
  }

  .slick-list {
    @include breakpoint-down(mobile) {
      padding-right: 0 !important;
      padding-left: 5.2rem;
    }
  }
}

button.slick-arrow {
  display: none !important;
}

.slick-list.draggable {
  width: 100%;
}

.slick-slide {
  padding: 0 0.6rem;
  @include breakpoint-down(mobile) {
    padding: 0 0.5rem;
  }
}

ul.slick-dots {
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: center;
  padding-top: 1rem;

  @include breakpoint-down(mobile) {
    margin-top: 0.9rem;
  }

  button {
    border-radius: 50%;
    height: 1rem;
    width: 1rem;
    display: block;
    font-size: 0;
    border: none;
    background-color: #36c6f4;
    border: 1px solid #36c6f4;
  }

  li {
    list-style: none;

    &:not(.slick-active) {
      button {
        background-color: transparent;
        border-color: white;
      }
    }
  }
}

.show__more {
  border-radius: 0.7rem;
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-position-y: 50%;
  padding: calc((71% - 41px) / 2) 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0.7rem;
  }

  a {
    text-decoration: none;
    border: 0.1rem solid #fff;
    color: #fff;
    border-radius: 0.3rem;
    z-index: 3;
    padding: 0.6rem 1rem;
    font-size: 1.8rem;
    font-weight: 700;
  }
}

.article--alt.is__rtl .article__body {
  direction: rtl;
}

.article--alt.is__ltr .article__body {
  direction: ltr;
}
