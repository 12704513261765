.scrollToTopBtn_wrapper {
    
    button#scrollToTopBtn {
        align-items: center;
        border: none;
        border-radius: 50%;
        color: #fff;
        cursor: pointer;
        display: none;
        font-size: 18px;
        font-weight: 700;
        justify-content: center;
        outline: none;
        padding: 10px;
        transition: all .3s ease-out;
        height: 60px;
        width: 60px;

        @media screen and (max-width: 480px) {
            height: 54px; 
            width: 54px; 
        }
    
        span {
            font-weight: bold;
        }
    }
    
    button#scrollToTopBtn:hover {
        transform: scale(1.1);
    }
}

html[dir="rtl"] {
    .scrollToTopBtn_wrapper {
       order: 2
    }
}