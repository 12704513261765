.closed_banner {

    margin: 2rem 0 0 0;
    
    @media screen and (min-width: 768px) {
           width: 100%;
           height: 33rem;
    }

    .closed_image {

        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 15px;

        @media screen and (min-width: 768px) {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
        }
    }
    
}