/* ------------------------------------------------------------ *\
	Socials
\* ------------------------------------------------------------ */

.socials {
  h6 {
    display: inline-block;
    margin: 0 0 1.8rem;
    font-size: 1.6rem;
    font-weight: 600;
    vertical-align: top;
    text-transform: uppercase;

    /*  Mobile  */

    @include breakpoint-down(mobile) {
      display: none;
    }
  }

  ul {
    list-style: none;

    li {
      display: inline-block;

      & + li {
        margin-left: 0.6rem;
      }

      a {
        position: relative;
        display: inline-block;
        width: 4.8rem;
        height: 4.8rem;
        transition: opacity 0.4s;

        &:hover {
          opacity: 0.7;
        }

        i {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}

/* ___________________________________________________ LTR ___________________________________________________ */

html[dir="rtl"] {
  .socials {
    ul {
      li {
        & + li {
          margin-left: 0;
          margin-right: 0.6rem;
        }
      }
    }
  }
}
