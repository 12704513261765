/* ------------------------------------------------------------ *\
	Slider Mobile
\* ------------------------------------------------------------ */

.slider-mobile {
  /*  Mobile  */
  overflow: hidden;

  @include breakpoint-down(mobile) {
    margin-right: -1.8rem;
  }

  .slick-arrow {
    display: none !important;
  }

  .slick-dots {
    list-style: none;
    text-align: center;
    margin: 0.9rem 5.2rem 0 0;

    button {
      display: none;
    }

    li {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border: 0.1rem solid #fff;
      border-radius: 5rem;
      transition: 0.4s;

      + li {
        margin-left: 1rem;
      }
    }

    .slick-active {
      background: #36c6f4;
      border-color: #36c6f4;
    }
  }

  .slick-list {
    margin: 0 -0.5rem;
    padding-right: 5.2rem;
  }

  .slick-slide {
    padding: 0 0.5rem;
  }
}

/* ___________________________________________________ LTR ___________________________________________________ */

html[dir="rtl"] {
  .slider-mobile {
    /*  Mobile  */

    @include breakpoint-down(mobile) {
      margin-left: -1.8rem;
      margin-right: 0;
    }

    .slick-dots {
      margin: 0.9rem 0 0 5.2rem;

      li {
        + li {
          margin-right: 1rem;
          margin-left: 0;
        }
      }
    }

    .slick-list {
      padding-left: 5.2rem;
      padding-right: 0;
    }
  }
}
