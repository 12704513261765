.translation-post {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;
  background: #d7edb5;
  width: fit-content;
  margin: 1.3rem 0;
  white-space: nowrap;

  @include breakpoint-down(mobile) {
    font-size: 1.1rem;
  }

  @include breakpoint-down(mobile-medium) {
    font-size: 1rem;
  }

  @include breakpoint-down(mobile-small) {
    font-size: 0.9rem;
  }

  .translation-image {
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  .translation-text {
    display: flex;
    align-items: center;
    line-height: 1;

    span + span {
      margin: 0 0.3rem;
    }

    .change-language {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.ar {
    .translation-text {
      direction: rtl;
    }

    .translation-image {
      order: 1;
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}

.footer-translation {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  margin-top: 1.5rem;
  white-space: nowrap;

  @include breakpoint-down(mobile) {
    font-size: 1.4rem;
    margin-top: 3rem;
  }

  @include breakpoint-down(mobile-medium) {
    font-size: 1.2rem;
  }
  @include breakpoint-down(mobile-small) {
    font-size: 0.9rem;
  }

  .translation-container {
    display: flex;

    a {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &.ar {
    .translation-container {
      direction: rtl;
    }
  }
}

html {
  &[dir="rtl"] {
    .footer-translation {
      .translation-container {
        direction: ltr;
      }
    }

    .translation-post {
      .translation-image {
        order: 1;
      }

      .translation-text {
        direction: ltr;
      }
    }
  }
}

.wpml-ls-current-language {
  display: none !important;
  list-style: none !important;
}
