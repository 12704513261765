/* Variables */
$font-size-base: 1.6rem;
$font-weight-base: 400;
$line-height-base: 1.5;

// $ff-base: "Baloo Bhaijaan 2", cursive;
// $ff-base: "Inter", Helvetica, Arial, sans-serif;
// $ff-base: "PTSerif", Helvetica, Arial, sans-serif;
// $ff-base: "CrimsonText", Helvetica, Arial, sans-serif;
// $ff-base: "Roboto", Helvetica, Arial, sans-serif;
$ff-base: "Raleway", Helvetica, Arial, sans-serif;

// $ff-serif: Georgia, "Times New Roman", Times, serif;

// $ff-base-ar: Arial, sans-serif;
$ff-base-ar: "Noto Sans Arabic", sans-serif;

$t-duration: 0.4s;

$shell-max-width: 122.8rem;
$shell-gutter: 1.8rem;

$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$calendar-background: #764085;
$calendar-color: #fff;
$c-black: #000;
$c-white: #fff;
$c-base: #055590;
$c-green: #d7edb5;
$c-gray: #e2e2e2;
$b-black: #343434;
$d-black: #404040;
$responsive-ios: 812px;

$breakpoints: (
  mobile-small: 374px,
  mobile-small-medium: 420px,
  mobile-medium: 575px,
  mobile-movies: 592px,
  mobile: 767px,
  admin-bar: 782px,
  tablet: 1023px,
  small-desktop: 1199px,
  large-desktop: 1365px,
);
